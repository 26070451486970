import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import Headline from '../../components/Headline/Headline';
import FlipperCard from '../../components/FlipperCard/FlipperCard';


import Aos from 'aos';
import 'aos/dist/aos.css';

import img_leistung_3 from '../../images/leistungen/img3.jpg';
import img_leistung_20 from '../../images/leistungen/img20.jpg';
import img_leistung_22 from '../../images/leistungen/img22.jpg';
import img_leistung_23 from '../../images/leistungen/img23.jpg';
import { renderers } from 'react-markdown';

const LeistungenPage = () => {
  
  useEffect(() => {
    Aos.init({duration: 2000 });
  }, []);

  return(
  <Layout headerWhite>
    <SEO title="Leistungen | Dr. Kostka - integrative Medizin und Ästhetik" description="Dr. K. Kostka bietet integrative Medizin und Ästhetik mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓" />
    {/*<div className="greenContainer">
      <ul>
        <li><Link to="/leistungen/aesthetik">Ästhetik</Link></li>
        <li className="active"><Link to="/leistungen/schmerz">Schmerz</Link></li>
        <li><Link to="/leistungen/ernaehrung">Ernährung</Link></li>
      </ul>
    </div>*/}
    <Section>
      <Container>
        <Headline h1>Schmerz</Headline>

        {/*<Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

              Klassische Schmerztherapie

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
              Das von der Weltgesundheitsorganisation (WHO) entwickelte Stufenschema zur medikamentösen Behandlung von Schmerzen bildet die Grundlage jeder individuellen Schmerztherapie.
              <br/>
              <br/>
              Bei chronischen Schmerzen reicht dieses zumeist nicht. Hier gilt es, ein sinnvolles Konzept aus vielschichtigen Behandlungen (Medikamente/physikalischen und psychologischen Verfahren) einzuleiten.
              <br/>
              <br/>
              Ich setze verstärkt auf ganzheitliche Therapien mit sanften, schonenden Verfahren auch aus der Naturheilkunde, um Ihnen nicht nur den Schmerz zu nehmen, sondern auch den Organismus wieder ins Gleichgewicht zu bringen und Selbstheilungskräfte anzuregen.
              <br/>
              <br/>
              Wichtige Ansatzpunkte der „Ganzheitlichen Schmerztherapie“ sind:
              <ul>
                <li>Muskeln und Faszien (z.B. Triggerpunktbehandlung)</li>
                <li>Positive Beeinflussung des Schmerzzentrums (u.a. über Akupunktur und Mesotherapie)</li>
                <li>Optimierung der Lebensweise mit Bewegung und Entspannung</li>
                <li>Vitalstoffoptimierung (Mikronährstoff-, Orthomolekulare Medizin)</li>
              </ul>
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

              Mesotherapie  -
                {' '}
                <i>Behandlung über die Haut</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                  <p>
                  Die Mesotherapie vereint therapeutische Grundsätze der Akupunktur, Neuraltherapie und Arzneimitteltherapie.
                  <br/>
                  <br/>
                  Diese schonende und sanfte Behandlungstechnik bringt kleinste Mengen an Wirkstoffen möglichst nahe an den Ort der Erkrankung.
                  <br/>
                  <br/>
                  Eine spezielle Wirkstoffmischung aus Medikamenten, Vitaminen, Aminosäuren und Antioxidantien wird entweder als Depot lokal auf die Haut aufgetragen oder mit winzigen Nadelstichen in die oberste Hautschicht wenige Millimeter tief eingebracht.
                  <br/>
                  Aus diesem Hautdepot werden die Wirkstoffe nach und nach in die tieferliegenden erkrankten Strukturen transportiert. Für einen schnellen und gleichzeitig lang anhaltenden Effekt sind daher nur sehr geringe Wirkstoffmengen nötig.
                  <br/>
                  <br/>
                  Anwendungsbereiche:
                  <ul>
                    <li>Schmerzzustände bei orthopädischen Erkrankungen</li>
                    <li>Sehnenreizungen</li>
                    <li>Durchblutungs- und Wundheilungsstörungen</li>
                    <li>Migräne und Spannungskopfschmerz</li>
                  </ul>
                  </p>
            </AccordionItemPanel>
          </AccordionItem>
          </Accordion>
        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

              PRP (Eigenbluttherapie) -
                {' '}
                <i>Selbstheilungskräfte des Körpers</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                  <p>
                  PRP steht für Platelet-Rich-Plasma bzw. plättchenreiches Plasma. Es handelt sich um eine risikoarme und effektive Behandlung mit körpereigenen Blutbestandteilen.
                  <br/>
                  <br/>
                  Es wird Ihnen eine kleine Menge Blut abgenommen, das durch eine spezielles Trennverfahren sicher und steril in einer Zentrifuge separiert wird. Das daraus gewonnene Blutplasma ist reich an Blutplättchen (Thrombozyten) und verschiedenen Wachstumsfaktoren. Diese wirken geweberegenerativ. Das gewonnene Plasma stimuliert den Körper, ähnlich wie bei der Wundheilung, an der erkrankten Stelle neue Zellen (Blutgefäße, Hyaluronsäure, Kollagen, Elastin) entstehen zu lassen. 
                  <br/>
                  <br/>
                  Die zellerneuernde und wundheilende Wirkung von PRP findet sowohl in der ästhetischen Medizin als auch Orthopädie seine Anwendung. 
                  <br/>
                  <br/>
                  Der Vorteil gegenüber allen anderen Therapien ist, dass nur Ihr eigenes Blut als Heilmittel fungiert, ohne Zusatz. Allergien oder Unverträglichkeitsreaktionen sind ausgeschlossen. 
                  <br/>
                  <br/>
                  Wann kommt PRP zum Einsatz?
                  <ul>
                    <li>Sportverletzungen:  Verkürzt die Regenerationszeit/ im Profisport seit Jahren bewährt</li>
                    <li>Arthrosetherapie: Gelenkinjektion, auch in Kombination mit Hyaluron</li>
                    <li>Sehnenentzündungen</li>
                    <li>Schlecht heilende Wunden</li>
                    <li>Frakturbehandlungen</li>
                    <li>Ästhetische Medizin</li>
                  </ul>
                  </p>
            </AccordionItemPanel>
          </AccordionItem>
          </Accordion>
        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

              Akupunktur  -
                {' '}
                <i>Harmonie durch Nadeln</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
              Akupunktur ist eine der ältesten Therapieform der Welt und gehört zur traditionellen chinesischen Medizin.
              <br/>
              <br/>
              Durch Einstiche an bestimmten Punkten auf Leitungsbahnen (Meridianen), die über den Körper ziehen, wird Einfluss auf die Lebensenergie des Körpers (Qi) genommen. Laut chinesischer Medizin entsteht Krankheit durch gestörten Energiefluss. Durch die Akupunktur soll der aus dem Takt geratene Energiestrom behutsam reguliert werden. 
              <br/>
              <br/>
              Nach modernen wissenschaftlichen Untersuchungen werden durch die Akupunktur bestimmte Zonen im Gehirn und Rückenmark stimuliert, die die Schmerzverarbeitung beeinflussen und zur Ausschüttung von stimmungsaufhellenden Substanzen („Glückshormone“) führt.
              <br/>
              <br/>
              Die Wirkung der Akupunktur ist in Abhängigkeit der Punktauswahl
              <ul>
                <li>schmerzlindernd,</li>
                <li>abschwellend,</li>
                <li>psychisch ausgleichend,</li>
                <li>immunmodulierend und/ oder</li>
                <li>durchblutungsfördernd.</li>
              </ul>
              Die Anwendungsgebiete der Akupunktur sind vielfältig und reichen von chronischen Schmerzen bei verschleißbedingten Erkrankungen des Bewegungsapparates (Arthrose) bis hin zur Prophylaxe bei Migräne und Raucherentwöhnung.
              <br/>
              <br/>
              Ich biete Ihnen neben der klassischen Körperakupunktur auch die Therapieoption, über spezielle Punkte am Kopf oder Ohr die Wirkung zu erzeugen/zu erzielen:
              <ul>
                <li>Schädelakupunktur nach Yamamoto</li>
                <li>Auriculo Therapie nach Nogier</li>
              </ul>
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>*/}
      </Container>
      <Container>
        <Row className="justify-content-md-center" data-aos="fade-up">
          <Col md={9}>
          <FlipperCard frontImage={img_leistung_3} title="Klassische Schmerztherapie" subtitle="Individuelle ganzheitliche Therapie" flipHint={true}>
            <div>
              Das von der Weltgesundheitsorganisation (WHO) entwickelte Stufenschema zur medikamentösen Behandlung von Schmerzen bildet die Grundlage jeder individuellen Schmerztherapie.
              <br/>
              <br/>
              Bei chronischen Schmerzen reicht dieses zumeist nicht. Hier gilt es, ein sinnvolles Konzept aus vielschichtigen Behandlungen (Medikamente/physikalische und psychologische Verfahren) einzuleiten.
              <br/>
              <br/>
              <Link className="whiteLinkText" to="/behandlung/klassische-schmerztherapie">Weiter lesen ...</Link>
              </div>
          </FlipperCard>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-md-center" data-aos="fade-up">
          <Col md={9}>
          <FlipperCard frontImage={img_leistung_20} title="Mesotherapie" subtitle="Behandlung über die Haut" flipHint={false}>
              <div>
                  Die Mesotherapie vereint therapeutische Grundsätze der Akupunktur, Neuraltherapie und Arzneimitteltherapie.
                  <br/>
                  <br/>
                  Diese schonende und sanfte Behandlungstechnik bringt kleinste Mengen an Wirkstoffen möglichst nahe an den Ort der Erkrankung.
                  <br/>
                  <br/>
                  <Link className="whiteLinkText" to="/behandlung/mesotherapie-schmerzbehandlung">Weiter lesen ...</Link>
                  </div>
          </FlipperCard>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-md-center" data-aos="fade-up">
          <Col md={9}>
          <FlipperCard frontImage={img_leistung_22} title="PRP (Eigenbluttherapie)" subtitle="Selbstheilungskräfte des Körpers" flipHint={false}>
            <div>
                  PRP steht für Platelet-Rich-Plasma bzw. plättchenreiches Plasma.
                  <br/>
                  <br/>
                  Es wird Ihnen eine kleine Menge Blut abgenommen, das durch ein spezielles Trennverfahren sicher und steril in einer Zentrifuge separiert wird. Das daraus gewonnene Blutplasma ist reich an Blutplättchen (Thrombozyten) und verschiedenen Wachstumsfaktoren. Diese wirken geweberegenerativ. Das gewonnene Plasma stimuliert den Körper, ähnlich wie bei der Wundheilung, an der erkrankten Stelle neue Zellen (Blutgefäße, Hyaluronsäure, Kollagen, Elastin) entstehen zu lassen. 
                  <br/>
                  <br/>
                  <Link className="whiteLinkText" to="/behandlung/prp-eigenblut-schmerzbehandlung">Weiter lesen ...</Link>
                  </div>
          </FlipperCard>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-md-center" data-aos="fade-up">
          <Col md={9}>
          <FlipperCard frontImage={img_leistung_23} title="Akupunktur" subtitle="Harmonie durch Nadeln" flipHint={false}>
            <div>
              Die Akupunktur ist eine der ältesten Therapieform der Welt und gehört zur traditionellen chinesischen Medizin.
              <br/>
              <br/>
              Durch Einstiche an bestimmten Punkten auf Leitungsbahnen (Meridianen), die über den Körper ziehen, wird Einfluss auf die Lebensenergie des Körpers (Qi) genommen. Laut chinesischer Medizin entsteht Krankheit durch gestörten Energiefluss. Durch die Akupunktur soll der aus dem Takt geratene Energiestrom behutsam reguliert werden. 
              <br/>
              <br/>
              <Link className="whiteLinkText" to="/behandlung/akupunktur">Weiter lesen ...</Link>
            </div>
          </FlipperCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </Layout>
)
      };

export default LeistungenPage;
